import { React, useState } from "react";
import { useContext, useEffect } from "react";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import { UserContext } from "../context/UserProvider";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const modelo = {
    nombre: "",
    correo: "",
    database: "",
    idRolNavigation: {
        descripcion :""
    }
}

const Inicio = () => {

    const { user } = useContext(UserContext)
    const [ dataUser, setDataUser ] = useState(modelo)
    const [data, setData] = useState("Not Found")

    const [stopStream, setStopStream] = useState(false)

    const dismissQrReader = () => {
        // Stop the QR Reader stream (fixes issue where the browser freezes when closing the modal) and then dismiss the modal one tick later
        setStopStream(true)
    }


    useEffect(() => {
        let dt = JSON.parse(user)
        console.log("dt",dt)
        setDataUser(dt)

    }, [])

    return (
        <>
            <Row>
                <Col sm={12} className="text-left">
                    <h2>Control de Almacenes</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <div className="card">
                        <img className="card-img-top" src={"./imagen/Foto003.jpg"} alt="Card image cap" />
                    </div>
                </Col>
                <Col sm={3}>
                    <FormGroup>
                        <Label>Nombre</Label>
                        <Input disabled value={dataUser.nombre} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Base de Datos</Label>
                        <Input disabled value={dataUser.database}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Rol</Label>
                        <Input disabled value={dataUser.idRolNavigation.descripcion}/>
                    </FormGroup>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>

            <Row>
                <>
                    <BarcodeScannerComponent
                        width={170}
                        height={350}
                        onUpdate={(err, result) => {
                            if (result)
                                setData(result.text);
                            else
                                setData("Not Found");
                        }}
                        stopStream={stopStream}
                    />
                    <p>{data}</p>

                </>
            </Row>
        </>
       
        )
}

export default Inicio;