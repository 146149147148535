/* eslint-disable react-hooks/rules-of-hooks */
import { useContext, useEffect, useState } from "react"
import { UserContext } from "../context/UserProvider"
import Swal from 'sweetalert2'
import { Navigate } from "react-router-dom"
import { Input } from "reactstrap";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const Login = () => {

    const [_correo, set_Correo] = useState("")
    const [_clave, set_Clave] = useState("")
    const [_dataBase, set_DataBase] = useState("")
    const [_sociedad, set_Sociedad] = useState("")
    const { user, iniciarSession } = useContext(UserContext)
    const [newdbLists, setnewdbLists] = useState([])
    const [datossesion, setdatossesion] = useState([])
    const datalst = [];
    const [data, setData] = useState("Not Found")
    const [stopStream, setStopStream] = useState(false)

    if (user != null) {
        return <Navigate to="/"/>
    }

    const onGetDataBaseList = async () => {
        let response = await fetch("api/dataBase/Lista");

        if (response.ok) {
            let data = await response.json()
            setnewdbLists(data)
        }

    }

    useEffect(() => {
        onGetDataBaseList();

        if (window.localStorage.getItem('sesion') !== null) {
            let data = JSON.parse(window.localStorage.getItem('sesion'))
            setdatossesion(data);
            set_Correo(data.nombre)
            set_DataBase(data.basedatos)
            set_Sociedad(data.nombre)
        }

    }, [])

    const onChangeFunc = (event) => {
        set_DataBase(event.target.value)
        set_Sociedad(event.target.innerHTML)
        console.log("_sociedad", _sociedad)
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let request = {
            correo: _correo,
            clave: _clave,
            basedatos: _dataBase,
            sociedad: _dataBase
        }

        const api = fetch("api/session/Login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(request)
        })
        .then((response) => {
            return response.ok ? response.json() : Promise.reject(response);
        })
        .then((dataJson) => {
            if (dataJson.idUsuario == 0) {
                Swal.fire(
                    'Opps!',
                    'No se encontro el usuario',
                    'error'
                )
            } else {
                iniciarSession(dataJson)
            }

        }).catch((error) => {
            Swal.fire(
                'Opps!',
                'No se pudo iniciar sessión',
                'error'
            )
        })
    }

    return (
        <div className="container">

            <div className="row justify-content-center">

                <div className="col-xl-10 col-lg-12 col-md-9">

                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">

                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Bienvenido</h1>
                                        </div>
                                        <form className="user" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input className="form-control form-control-user" aria-describedby="emailHelp" placeholder="Usuario APP"
                                                    value={_correo}
                                                    onChange={(e) => set_Correo(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="form-control form-control-user" placeholder="Contraseña"
                                                    value={_clave}
                                                    onChange={(e) => set_Clave(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <Input type="select" value={_dataBase} onChange={onChangeFunc} >
                                                    <option value="-----">----</option>
                                                    {newdbLists.map((_DataBase) => (
                                                        <option key={_DataBase.baseDatos} value={_DataBase.baseDatos}>
                                                            {_DataBase.nombre}</option>))}
                                                </Input>

                                            </div>
                                            <div className="form-group">
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-user btn-block"> Ingresar </button>

                                        </form>
                                        <hr></hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <>
                            <BarcodeScannerComponent
                                width={170}
                                height={350}
                                onUpdate={(err, result) => {
                                    if (result)
                                        setData(result.text);
                                    else
                                        setData("Not Found");
                                }}
                                stopStream={stopStream}
                            />
                            <p>{data}</p>

                        </>
                </div>

            </div>

        </div>
    )
}

export default Login